<template>
    <header class="sticky top-0 z-10 bg-white border-b border-gray-200">
        <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div class="flex lg:flex-1">
                <router-link to="/" @click.native="scrollToTop" class="-m-1.5 p-1.5">
                    <span class="sr-only">Brocon IT</span>
                    <img class="h-8 md:h-10 w-auto" src="../../public/logo.png" alt="Broconit GmbH" />
                </router-link>
            </div>
            <div class="flex xl:hidden">
                <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    @click="mobileMenuOpen = true">
                    <span class="sr-only">Open main menu</span>
                    <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <PopoverGroup class="hidden xl:flex xl:gap-x-12">

                <router-link to="/" @click.native="scrollToContainer('kompetenzen')"
                    class="flex items-center text-sm font-semibold leading-6 text-gray-900">Kompetenzen</router-link>
                <router-link to="/" @click.native="scrollToContainer('services')"
                    class="flex items-center text-sm font-semibold leading-6 text-gray-900">Services</router-link>
                <router-link to="/" @click.native="scrollToContainer('rockstable')"
                    class="flex items-center text-sm font-semibold leading-6 text-gray-900">Rockstable</router-link>
                <router-link to="/" @click.native="scrollToContainer('news')"
                    class="flex items-center text-sm font-semibold leading-6 text-gray-900">News</router-link>
                <router-link to="/karriere"
                    class="flex items-center text-sm font-semibold leading-6 text-gray-900">Karriere</router-link>
                <router-link to="/kontakt"
                    class="flex items-center text-sm font-semibold leading-6 text-gray-900">Kontakt</router-link>

                <a href="https://brocon-shop.com/" class="flex items-center text-sm font-semibold leading-6 text-gray-900">
                    <span
                        class="rounded-full bg-brocon/10 hover:bg-green-600/20 flex px-3 py-1 text-sm font-semibold leading-6 text-green-600 ring-1 ring-inset ring-green-600/10 my-auto">
                        <component :is="ShoppingCartIcon" class="h-5 w-5 mr-2 flex-none text-green-400"
                            aria-hidden="true" />
                        Zum Shop
                    </span>
                </a>

            </PopoverGroup>
        </nav>

        <Dialog as="div" class="xl:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
            <div class="fixed inset-0 z-10" />
            <DialogPanel
                class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div class="flex items-center justify-between">
                    <router-link to="/" class="-m-1.5 p-1.5">
                        <span class="sr-only">Your Company</span>
                        <img class="h-8 w-auto" src="../../public/logo.png" alt="Broconit GmbH" />
                    </router-link>
                    <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
                        <span class="sr-only">Close menu</span>
                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div class="mt-6 flow-root">
                    <div class="-my-6 divide-y divide-gray-500/10">
                        <div class="space-y-2 py-6">

                            <router-link to="/" @click.native="scrollToContainer('kompetenzen')"
                                @click="mobileMenuOpen = false"
                                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Kompetenzen</router-link>
                            <router-link to="/" @click.native="scrollToContainer('services')"
                                @click="mobileMenuOpen = false"
                                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Services</router-link>
                            <router-link to="/" @click.native="scrollToContainer('rockstable')"
                                @click="mobileMenuOpen = false"
                                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Rockstable</router-link>
                            <router-link to="/" @click.native="scrollToContainer('news')" @click="mobileMenuOpen = false"
                                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">News</router-link>
                            <router-link to="/karriere" @click="mobileMenuOpen = false"
                                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Karriere</router-link>
                            <router-link to="/kontakt" @click="mobileMenuOpen = false"
                                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Kontakt</router-link>

                            <a href="https://brocon-shop.com/" @click="mobileMenuOpen = false"
                                class="flex items-center text-sm font-semibold leading-6 text-gray-900">
                                <span
                                    class="rounded-full bg-brocon/10 flex px-3 py-1 text-sm font-semibold leading-6 text-green-600 ring-1 ring-inset ring-green-600/10 my-auto">
                                    <component :is="ShoppingCartIcon" class="h-5 w-5 mr-2 flex-none text-green-400"
                                        aria-hidden="true" />
                                    Zum Shop
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </DialogPanel>
        </Dialog>
    </header>
</template>
  
<script setup>
import { ref } from 'vue'
import {
    Dialog,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
} from '@headlessui/vue'
import {
    Bars3Icon,
    ServerIcon,
    BriefcaseIcon,
    SquaresPlusIcon,
    XMarkIcon,
    ShoppingCartIcon,
} from '@heroicons/vue/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon, RectangleGroupIcon } from '@heroicons/vue/20/solid'

const products = [
    {
        name: 'IT-Infrastruktur',
        description: 'Profitieren Sie von unserem großen Hardware-Sortiment für Ihre IT-Infrastruktur',
        href: 'kompetenzen#it',
        icon: ServerIcon,
    },
    {
        name: 'Autorisierte Distributionen',
        description: 'Werden Sie unser Partner und lassen Sie sich von unseren Vorteilen überzeugen',
        href: 'kompetenzen#distribution',
        icon: BriefcaseIcon,
    },
    {
        name: 'Technische Beratung',
        description: 'Wir beraten Sie gerne bei der Planung und Umsetzung Ihrer IT-Projekte',
        href: 'kompetenzen#beratung',
        icon: PhoneIcon
    },
]
const callsToAction = [
    { name: 'Support', href: '/kontakt', icon: PlayCircleIcon },
    { name: 'Kontakt Verkauf', href: '/kontakt', icon: PhoneIcon },
    { name: 'Alle Kompetenzen', href: '/kompetenzen', icon: RectangleGroupIcon },
]

const mobileMenuOpen = ref(false)
const showSubmenu = ref(false)

</script>

<script>
export default {
    mounted() {
        this.scrollToAnchor();
    },
    methods: {
        scrollToContainer(containerId) {
            setTimeout(() => {
                const container = document.getElementById(containerId);
                if (container) {
                    container.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100); // Anpassbare Verzögerung, je nach Bedarf
        },
        scrollToAnchor() {
            setTimeout(() => {
                const hash = window.location.hash;
                if (hash) {
                    const containerId = hash.substring(1);
                    this.scrollToContainer(containerId);
                }
            }, 100); // Anpassbare Verzögerung, je nach Bedarf
        },
        scrollToTop() {
            window.scrollTo(0,0);
        }
    }
};  
</script>
