import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/karriere',
    name: 'karriere',
    component: () => import('../views/KarriereView.vue')
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: () => import('../views/KontaktView.vue')
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: () => import('../views/DataPrivacyView.vue')
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: () => import('../views/ImprintView.vue')
  },
  {
    path: '/agb',
    name: 'agb',
    component: () => import('../views/LegalView.vue')
  },
  {
    path: '/news/:id',
    name: 'newsdetail',
    component: () => import('../views/NewsDetailView.vue')
  },
  {
    path: '/jobs/:id',
    name: 'jobdetail',
    component: () => import('../views/JobDetailView.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/NotFoundView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
