<script setup>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
</script>

<template>
  <Header />

  <router-view />

  <Footer />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>

<script>
export default {
  mounted() {
    this.scrollToAnchor();
  },
  methods: {
    scrollToContainer(containerId) {
      setTimeout(() => {
        const container = document.getElementById(containerId);
        if (container) {
          container.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // Anpassbare Verzögerung, je nach Bedarf
    },
    scrollToAnchor() {
      setTimeout(() => {
        const hash = window.location.hash;
        if (hash) {
          const containerId = hash.substring(1);
          this.scrollToContainer(containerId);
        }
      }, 100); // Anpassbare Verzögerung, je nach Bedarf
    }
  }
};  
</script>
